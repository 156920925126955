.inactiveContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 3rem;
  margin-left: -3rem;
}

.inactiveContainer svg {
  width: 20rem;
  height: 15rem;
  color: #0088c2;
}

.inactiveContainer span {
  color: #0088c2;
  font-weight: 600;
}

@media (max-width: 768px) {
  .inactiveContainer {
    font-size: 1.3rem;
    margin-left: -2.5rem;
  }

  .inactiveContainer svg {
    width: calc(20rem / 1.2);
    height: calc(15rem / 1.2);
    color: #0088c2;
  }
}
