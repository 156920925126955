.tableContainer {
  width: auto;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .tableContainer {
    max-width: 80vw;
    overflow: auto;
    /* background-color: aliceblue; */
  }
}
