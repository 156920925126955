.dropdown {
  width: 7.85rem;
  height: 2.6rem;
  appearance: none;
  background-image: url("https://thenounproject.com/api/private/icons/3456470/edit/?backgroundShape=SQUARE&backgroundShapeColor=%23000000&backgroundShapeOpacity=0&exportSize=752&flipX=false&flipY=false&foregroundColor=%23000000&foregroundOpacity=1&imageFormat=png&rotation=0&token=gAAAAABkL11IcwFpivYdIODENtG_1nh_wBSf3uTbIiRZIRAENeB5ngIbQNvzBDCnzKgH6X_XSVmRgf-_xctnJe153bhiY5V09w%3D%3D");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1em;
  border: 1px solid #e9e9e9;
  border-radius: 0.65rem;
  padding-left: 1rem;
}

.dropdownContainer {
  margin-right: 1rem;
}

.dropdownContainer label {
  color: #9a9a9a;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
  font-weight: 400;
}
