.inputContainer textarea {
  appearance: none;
  border: 1px solid #e9e9e9;
  height: 20rem;
  width: 30rem;
  border-radius: 0.65rem;
  padding: 1rem 1rem 1rem 1rem;
  outline-color: #0088c2;
  margin-bottom: 0.5rem;
  color: black;
}

.inputContainer label {
  color: #9a9a9a;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
  font-weight: 400;
  width: 30rem;
}

.inputContainer span {
  color: #9a9a9a;
  font-weight: 600;
}

@media (max-width: 768px) {
  .inputContainer textarea {
    width: 70vw;
  }

  .inputContainer label {
    width: 70vw;
  }
}
