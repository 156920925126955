.entriesContainer {
  background-color: #f4f4f4;
  border-radius: 0.8rem;
  padding: 1rem;
  color: #7e7e7e;
}

.entriesContainer div {
  border: 1px solid #e7e7e7;
  margin: 0.3rem 0 0.3rem 0;
  font-size: 0.8rem;
  border-radius: 0.7rem;
  padding: 0.3rem;
  cursor: pointer;
}

.entriesContainer div:hover {
  background-color: #e3e3e3;
}
