$radius: 55px;
$circumf: 3.1416 * $radius * 2;
$check-len: 75px;

@keyframes outline {
  from {
    stroke-dasharray: 0, $circumf;
  }
  to {
    stroke-dasharray: $circumf, $circumf;
  }
}

#outline {
  animation: 0.38s ease-in outline;
  transform: rotate(0deg);
  transform-origin: center;
}

@keyframes circle {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

#white-circle {
  animation: 0.35s ease-in 0.35s forwards circle;
  transform: none;
  transform-origin: center;
}

@keyframes check {
  from {
    stroke-dasharray: 0, $check-len;
  }
  to {
    stroke-dasharray: $check-len, $check-len;
  }
}

#check {
  animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
  stroke-dasharray: 0, $check-len;
}

@keyframes check-group {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.09);
  }

  to {
    transform: scale(1);
  }
}

#check-group {
  animation: 0.32s ease-in-out 1.03s check-group;
  transform-origin: center;
}
