.mainSideContainer img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 20rem;
  margin: 0 0.8rem 0 0rem;
}

.mainSideContainer {
  font-size: 0.75rem;
  margin: 1.5rem 0 0 0;
}

.mainSideContainer button {
  border: 1px solid #cfcfcf;
  background-color: white;
  color: #909090;
  font-size: 0.65rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  padding: 0.3rem;
  margin: 0.5rem 0.3rem 0.3rem 0.3rem;
  cursor: pointer;
}

.mainSideContainer button:hover {
  background-color: rgb(247, 247, 247);
}

.mainSideContainer svg {
  color: black;
  height: 1rem;
}

.mainSideContainer span {
  font-size: 0.55rem;
}

.mainSideContainer > div {
  justify-content: center;
  align-items: center;
}

.mainSideContainer input[type="checkbox"] {
  position: relative;
  width: 2rem;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background: rgb(210, 210, 210);
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
}

.mainSideContainer input[type="checkbox"]::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

.mainSideContainer input[type="checkbox"]:checked::before {
  transform: translateX(100%);
  background: #fff;
}

.mainSideContainer input[type="checkbox"]:checked {
  background: #0088c2;
}

.language {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.3rem 0 0 0;
}

.language label {
  margin: 0 0.5rem 0 0.5rem;
  color: #909090;
}

.userDetails {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .userDetails {
    flex-direction: column;
    text-align: center;
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .mainSideContainer img {
    width: 6rem;
    height: 6rem;
    border-radius: 20rem;
    margin: 0;
  }

  .mainSideContainer {
    padding-bottom: 2rem;
  }
}
