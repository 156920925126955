.dashboardContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 21.3rem);
  max-width: 65rem;
  font-weight: 300;
}

.dashboardSubContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statistics {
  background-color: #f0f8fb;
  border: 1px solid #0088c2;
  border-radius: 0.7rem;
  padding: 1.5rem;
  width: calc(50% - 3.5rem);
  height: 12.5rem;
  color: #00567a;
  position: relative;
  margin-bottom: 0.8rem;
}

.statistics button {
  border-color: #0088c2;
  color: #00567a;
}

.notifications {
  background-color: #f6ffe3;
  border: 1px solid #075300;
  border-radius: 0.7rem;
  padding: 1.5rem;
  width: calc(50% - 3.5rem);
  height: 12.5rem;
  color: #075300;
  position: relative;
  margin-bottom: 0.8rem;
}

.notifications button {
  border-color: #075300;
  color: #075300;
}

.activityLog {
  background-color: #f5f5f5;
  border: 1px solid #838383;
  border-radius: 0.7rem;
  padding: 1.5rem;
  height: 12.5rem;
  color: #3f3f3f;
  position: relative;
}

.activityLog button {
  border-color: #3f3f3f;
  color: #3f3f3f;
  min-width: 5rem;
}

.activityLog div {
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
}

.activityLog span {
  font-weight: 500;
  color: #858585;
}

.activityLog p {
  margin-bottom: 0.7rem;
}

.activityLogList {
  height: 8.5rem;
  overflow: auto;
}

.dashButton {
  border: 1px solid;
  background-color: #00000000;
  border-radius: 0.4rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  font-size: 0.7rem;
}

.bottomRightButton {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.bottomLeftButton {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}

.nRequests {
  font-size: 4rem;
  font-weight: 600;
  color: #616161;
  margin-top: 1.5rem;
  margin-bottom: -1rem;
}

.totalRevenue {
  font-size: 1.8rem;
  font-weight: 600;
  color: #0088c2;
}

.chartWrapper {
  width: 13rem;
  height: 8rem;
  position: absolute;
  bottom: 1rem;
  left: 1.5rem;
}

@media (max-width: 768px) {
  .dashboardContainer {
    width: calc(100vw - 6rem);
  }

  .dashboardSubContainer {
    flex-direction: column;
  }

  .statistics {
    width: auto;
    height: 15rem;
  }

  .chartWrapper {
    bottom: 3.5rem;
  }

  .notifications {
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .dashboardSubContainer {
    flex-direction: column;
  }

  .statistics {
    width: auto;
  }

  .notifications {
    width: auto;
  }
}
