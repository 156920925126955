.rolesContainer {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .rolesContainer {
    flex-direction: column;
  }
}
