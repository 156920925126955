.dropdownwrapper {
  position: relative;
  display: inline-block;
}

.dropdownbutton {
  cursor: pointer;
  appearance: none;
  border: 1px solid #e9e9e9;
  height: 2.25rem;
  width: 12.5rem;
  border-radius: 0.65rem;
  padding: 0 1rem;
  background-color: white;
  color: #9a9a9a;
  font-size: 0.8rem;
  font-weight: 400;
  outline-color: #0088c2;
}

.dropdownmenu {
  position: relative;
  left: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #e9e9e9;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 1;
  border-radius: 0.65rem;
  width: 12.5rem;
}

.dropdownitem {
  display: block;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 400;
  color: #9a9a9a;
  margin-bottom: 0.25rem;
}

.dropdownitem input[type="checkbox"] {
  margin-right: 5px;
}

.subdropdownitem {
  display: block;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 400;
  color: #9a9a9a;
  margin-bottom: 0.25rem;
  margin-left: 1rem;
}

.subdropdownitem input[type="checkbox"] {
  margin-right: 5px;
}

.mainLabel {
  color: #9a9a9a;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
  font-weight: 400;
}

.mainLabel span {
  color: #9a9a9a;
  font-weight: 600;
}

@media (max-width: 768px) {
  .mainLabel {
    width: 70vw;
  }

  .dropdownbutton {
    width: 70vw;
  }

  .dropdownmenu {
    width: 70vw;
  }
}
