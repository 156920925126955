.logo {
  height: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logo p {
  font-size: 1.5rem;
  margin: 1rem;
}

.logo span {
  font-weight: 600;
  color: #0088c2;
}

.navContainer {
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  width: 10.9rem;
  height: 2.9rem;
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  color: #868686;
  cursor: pointer;
  font-weight: 500;
  background-color: #ffffff;
  margin: 0.7rem 0 0.7rem 0;
  outline: 1.5px solid #ffffff;
  transition: 0.3s;
}

.item svg {
  padding: 0 1rem 0 1rem;
}

.item:hover {
  outline: 1.5px solid #0088c2;
}

.active {
  outline: 1px solid #0088c2;
  background-color: #f0f8fb;
  color: #0088c2;
}

.subItem {
  color: #9a9a9a;
  width: calc(10.9rem - 0.85rem * 2);
  font-size: 0.85rem;
  padding: 0.75rem;
  border-radius: 0.8rem;
  cursor: pointer;
}

.subItem:hover {
  background-color: #ececec;
}

.subSubItem {
  background-color: #ececec00;
  color: #9a9a9a;
  width: calc(10.9rem - 0.85rem * 2);
  font-size: 0.85rem;
  padding: 1.5rem 0 0 0;
  cursor: pointer;
}

.subSubItem:hover {
  text-decoration: underline;
}

.subSubItemTitle {
  font-weight: 500;
  cursor: pointer;
}

.subSubItemContainer {
  margin-top: 0.65rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
}

@media (max-width: 768px) {
  .logo {
    display: none;
  }

  .navContainer {
    position: absolute;
    background-color: white;
    width: 100vw;
    height: 100vh;
    padding-top: 15vh;
    z-index: 2;
    left: -100vw;
    transition: 0.3s;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .navToggled {
    left: 0;
  }
}
