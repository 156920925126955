.multiCheckboxContainer {
  display: flex;
  flex-direction: column;
  width: 13rem;
  transform: translate(0.4rem, 0);
}

.multiCheckboxContainer p {
  display: inline;
  margin-right: 1rem;
}

.multiCheckboxContainer svg {
  margin-right: 0.5rem;
}

.multiCheckboxContainer label {
  display: flex;
  margin: 0.5rem 0 0.5rem 0;
  align-items: center;
  width: 10rem;
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.4;
}

.multiCheckboxContainer input {
  transform: scale(2);
  -webkit-appearance: none;
  -o-appearance: none;
  accent-color: #0088c2;
  appearance: auto;

  margin: 0 1rem 0 0;
}

.multiCheckboxContainer .active {
  opacity: 1;
}

@media (max-width: 768px) {
  .multiCheckboxContainer {
    width: 80vw;
    /* transform: translate(0.5rem, 0); */
  }
  .multiCheckboxContainer label {
    font-size: 1.2rem;
    width: 80vw;
    margin: 0.3rem 0 0.3rem 0;
  }

  .multiCheckboxContainer input {
    margin: 0 1.5rem 0 0;
  }

  .multiCheckboxContainer p {
    margin-right: 2rem;
  }
}
