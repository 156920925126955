.mainView {
  /* background-color: grey; */
  height: 2500px;
  width: 2500px;
  display: flex;
  flex-direction: row;
  /* background-color: aqua; */
}

.unassignedView {
  display: flex;
  flex-direction: row;
}

.floorViewObjButton {
  height: 2.5rem;
  align-self: flex-end;
  border-radius: 0.6rem;
  border: none;
  background: #e8e8e8;
  color: #929292;
}

.floorViewObjButton:hover {
  background: #dbdbdb;
}

.unassignedShop {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 0.6rem;
  border: 1px solid #c5c5c5;
  margin: 0.5rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.above {
  z-index: 3;
}

.moreAbove {
  z-index: 4;
}

.unassignedButton {
  width: 4.5rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 0.5rem;
  border: 1px solid #c5c5c5;
  background-color: white;
  color: #929292;
  font-size: 0.7rem;
  border-radius: 0.75rem;
}

.addBtn {
  width: 7.5rem;
  height: 4rem;
  border: 1px solid #c5c5c5;
  margin: 1rem;
  border-radius: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.shop {
  font-size: 0.6rem;
  margin-bottom: 1rem;
  border: 1px solid #c5c5c5;
  border-radius: 0.6rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.shopDet {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  background-color: rgb(220, 255, 168);
}

.shop:hover {
  background-color: rgb(242, 242, 242);
}

.unassigned {
  height: 100px;
  border: 1px solid #c5c5c5;
}

.topItem {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

.top {
  display: flex;
  flex-direction: row;
  margin-left: 5rem;
}

.shop img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.dltIcon {
  position: absolute;
  background: none;
  border: none;
  right: 0;
  transform: translate(2.5rem, 0);
}

.dltIcon img {
  /* display: none; */
  height: 1rem;
  border-radius: 60rem;
  padding: 0.5rem;
}

.dltIcon img:hover {
  background-color: rgb(255, 214, 214);
}

.shop .dltIcon img {
  display: none;
}

.shop:hover .dltIcon img {
  display: inline;
}
