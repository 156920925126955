.dialogContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.239);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 3;
}

.dialogContainer .mainContainer {
  width: 30rem;
  height: 10rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
}

.dialogContainer .buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dialogContainer button {
  width: 8rem;
  border: none;
  margin: 0.5rem;
  padding: 1rem;
  border-radius: 1rem;
  transition: 0.3s;
}

.dialogContainer p {
  margin-bottom: 0.5rem;
}

.green:hover {
  background-color: green;
  color: white;
}

.red:hover {
  background-color: red;
  color: white;
}

/* Add this at the end of your CSS file */
@keyframes fadeIn {
  0% {
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }
  100% {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.239);

    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease-out;
}

@media (max-width: 768px) {
  .dialogContainer .mainContainer {
    width: 15rem;
  }

  .dialogContainer p {
    width: 12rem;
    font-size: 1rem;
  }
}
