
  
  .inputContainer label {
    color: #9a9a9a;
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
    font-weight: 400;
  }
  
  .inputContainer span {
    color: #9a9a9a;
    font-weight: 600;
  }
  
  