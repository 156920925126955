.headerContainer {
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  border-radius: 0.8rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #e8e8e8;
  margin-bottom: 0.5rem;
  transition: 0.3s;
}

.headerContainer label {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.divider {
  background-color: white;
  width: 0.15rem;
}

@media print {
  .headerContainer {
    display: flex;
    justify-content: space-between;
    width: 80vw;
  }

  .headerContainer label {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 0.5px solid black; */
    background-color: rgb(199, 199, 199);
    print-color-adjust: exact;
    padding: 0;
    border-radius: 0;
    width: 100% !important;
    text-align: left;
  }

  .divider {
    display: none;
  }
}
