@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 16px;
}

.forceBold label {
  font-weight: bold !important;
  color: black !important;
}

.forceBold span {
  font-weight: bold !important;
  color: black !important;
}

.mainContainer {
  display: 100vh;
  display: flex;
  flex-direction: row;
  padding-bottom: 10rem;
}

.pageContent {
  padding-left: 3rem;
  height: 100vh;
  display: inline;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-wrap > div {
  margin-bottom: 0.5rem;
}

.logoPhone {
  display: none;
}

.page-animation {
  position: relative;
  overflow: hidden;
}

.color-primary {
  color: #0088c2;
}

@media (max-width: 768px) {
  .logoPhone {
    display: flex;
    position: relative;
    left: 1.5rem;
    top: 1rem;
  }

  .logoPhone p {
    font-size: 1.5rem;
    margin: 1rem;
  }

  .logoPhone span {
    font-weight: 600;
    color: #0088c2;
  }

  .pageContent {
    padding-left: 2.5rem;
  }
}

.hidePrint {
}

.printOnly {
  display: none;
}

.allBlack {
  background-color: #c5c5c5;
  padding: 1rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.allBlack div {
  color: black !important;
}
.allBlack label {
  color: black !important;
}
.allBlack span {
  color: black !important;
}

@media print {
  .hidePrint {
    display: none;
  }

  .printOnly {
    display: inline-block;
  }
}
