.item {
  width: 10rem;
  height: 3rem;
  background-color: #f0f8fb;
  border: 1px solid #0088c2;
  border-radius: 0.7rem;
  margin-bottom: 0.3rem;
  color: #0088c2;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.item:hover {
  background-color: #d5f3ff;
}

.back {
  width: 10rem;
  height: 3rem;
  background-color: #ffeded;
  border: 1px solid rgb(177, 30, 30);
  border-radius: 0.7rem;
  margin-bottom: 0.3rem;
  color: rgb(177, 30, 30);
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.back:hover {
  background-color: #ffd7d7;
}
