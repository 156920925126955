.pButton {
  height: 2.9rem;
  min-width: 10.9rem;
  border-radius: 0.7rem;
  border: 1px solid #0088c2;
  background-color: #f0f8fb;
  color: #0088c2;
  transition: 0.2s;
  font-weight: 500;
}

.bnw {
  border: 1px solid black;
  background-color: #e0e0e0;
  color: black;
}

.bnw:hover {
  background-color: black !important;
  color: white !important;
}

.pButton:hover {
  background-color: #0088c2;
  color: white;
}

.green {
  height: 2.9rem;
  min-width: 10.9rem;
  border-radius: 0.7rem;
  /* border: 1px solid #0088c2; */
  border: none;
  background-color: #3fa700;
  color: #ffffff;
  transition: 0.2s;
  font-weight: 500;
}

.green:hover {
  background-color: #28b700;
}

.long {
  min-width: 22.8rem;
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .pButton {
    min-width: 8.9rem;
  }

  .green {
    min-width: 8.9rem;
  }

  .long {
    min-width: 18.9rem;
  }
}
