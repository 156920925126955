.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: -3rem;
}

.container p {
  font-size: 2rem;
  margin: 1rem;
}

.container span {
  font-weight: 600;
  color: #0088c2;
}

.container input {
  width: 17.45rem;
  height: 3.1rem;
  margin: 0.3rem;
  border: none;
  border-radius: 0.65rem;
  background-color: #f4f4f4;
  padding: 0 1rem 0 1rem;
  outline-color: #0088c2;
}

.container button {
  margin: 1rem;
}

.container a {
  color: #9b9b9b;
  font-size: 0.9rem;
  margin: 1rem;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}

.container a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .container {
    margin-left: -2.5rem;
  }
}
