.blueItem {
  display: inline;
  padding: 0.4rem;
  background-color: #c4edff;
  border: 1px solid #008cc9;
  font-size: 0.8rem;
  display: flex;
  align-self: flex-start;
  color: #006793;
  border-radius: 0.7rem;
  text-decoration: none;
  margin: 0.25rem 0 0.25rem 0;
  min-width: 15rem;
}

.blueItem:hover {
  background-color: #a3e4ff;
}
