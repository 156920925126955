.titleContainer {
  height: 10rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.titleContainer img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 3rem;
  margin-right: 1rem;
}

.title {
  font-size: 2.4rem;
  font-weight: 700;
}

.subTitle {
  color: #9a9a9a;
  font-size: 1rem;
}

.back {
  margin-right: 0.7rem;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 40rem;
  width: 3.2rem;
  height: 3.2rem;
}

.back:hover {
  border: 1px solid #0088c2;
}

.back svg {
  font-size: 3rem;
  color: #0088c2;
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
    line-height: 2rem;
  }

  .titleContainer {
    width: 70vw;
  }
}
