.inputContainer {
  align-items: center;
  margin: 0.7rem 0 0.7rem 0;
}

.inputContainer label {
  color: #9a9a9a;
  font-size: 0.8rem;
  font-weight: 600;
}

.inputContainer input[type="checkbox"] {
  position: relative;
  width: 1.4rem;
  height: 0.7rem;
  -webkit-appearance: none;
  appearance: none;
  background: rgb(210, 210, 210);
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  margin-left: 0.4rem;
}

.inputContainer input[type="checkbox"]::before {
  content: "";
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

.inputContainer input[type="checkbox"]:checked::before {
  transform: translateX(100%);
  background: #fff;
}

.inputContainer input[type="checkbox"]:checked {
  background: #0088c2;
}
