.itemContainer {
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  border-radius: 0.8rem;
  margin-bottom: 0.2rem;
  font-size: 0.8rem;
  background-color: #f8f8f8;
  transition: 0.3s;
  /* overflow: auto; */
}

.itemContainer label {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.itemContainer:hover {
  background-color: #e6e6e6;
}

.divider {
  background-color: white;
  width: 0.15rem;
}

@media print {
  .itemContainer {
    border-radius: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 80vw;
    /* overflow: auto; */
  }

  .itemContainer label {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 0.5px solid rgb(121, 121, 121); */
    padding: 0;
    width: 100% !important;
    text-align: start;
    display: flex;
    justify-content: left;
  }

  .divider {
    display: none;
  }
}
