.fieldSection {
  display: flex;
  flex-direction: column;
  margin-right: 5rem;
  min-width: 17rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .fieldSection{
    
    margin-bottom: 2rem;
  }
}
