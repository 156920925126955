.notificationContainer{
    width: 20rem;
    height: 30rem;
    position: fixed;
    right: 3.3rem;
    top: 7.6rem;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
    border-radius: 1rem;
    z-index: 3;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.589);
    backdrop-filter: blur(5px); /* Add this line for blur effect */
}

.notificationHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
}

.notificationHeader a{
    
    color: #646464;
}

.notificationsList{
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.notificationsList div{
    padding: 2rem;
    border-top: 1px solid #6464644d;
    cursor: pointer;
    transition: 0.3s;
    color: rgb(58, 58, 58);
}

.notificationsList div:hover{
    background-color: rgba(255, 255, 255, 0.884);
}

@media (max-width: 768px) {
    .notificationContainer{
        top: 5rem;
        right: 2rem;
    }

}