.redItem {
  background-color: rgb(255, 175, 175);
  color: rgb(126, 0, 0);
  padding: 0.7rem;
  font-size: 0.8rem;
  margin: 0.5rem 0 0.5rem 0;
  border-radius: 0.3rem;
  text-align: center;
}

.redItem:hover {
  background-color: rgb(255, 95, 95);
  color: white;
}
