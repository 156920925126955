.statsContainerTop {
  display: flex;
  flex-direction: row;
  width: 50vw;
  display: flex;
  justify-content: space-between;
  height: 6.25rem;
  font-size: 0.75rem;
}

.statsRec {
  border: 1px solid #900;
  background-color: #fbf0f0;
  padding: 1rem;
  width: calc(50% - 2.5rem);
  border-radius: 0.45rem;
  color: #900;
}

.statsCol {
  border: 1px solid #075300;
  color: #075300;
  background-color: #f6ffe3;
  padding: 1rem;
  width: calc(50% - 2.5rem);
  border-radius: 0.45rem;
}

.statsContainerTop p {
  font-size: 2rem;
  font-weight: 600;
}

.statsContainerBottom {
  display: flex;
  flex-direction: row;
  width: 50vw;
  display: flex;
  justify-content: space-between;
  height: 22rem;
  font-size: 0.75rem;
  margin-top: 1rem;
}

.barContainer {
  width: calc(50% - 2.5rem);
  padding: 1rem;
  border: 1px solid #c5c5c5;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pieContainer {
  width: calc(50% - 2.5rem);
  padding: 1rem;
  border: 1px solid #c5c5c5;
  border-radius: 1rem;
  /* background-color: rebeccapurple; */
}

@media (max-width: 768px) {
  .statsContainerTop {
    flex-direction: column;
    height: auto;
    width: 70vw;
  }
  .statsContainerBottom {
    flex-direction: column;
    height: auto;
    width: 70vw;
  }

  .statsRec {
    width: 70vw;
    margin-bottom: 1rem;
  }

  .barContainer {
    width: 70vw;
    height: 20rem;
    margin-bottom: 1rem;
  }

  .pieContainer {
    width: 70vw;
    margin-bottom: 1rem;
  }

  .statsCol {
    width: 70vw;
  }
}
