.edit {
  width: 3.2rem;
  height: 3.2rem;
  background-color: #f8f8f8;
  border-radius: 0.8rem;
  transform: translate(0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(80, 80, 80);
  transition: 0.3s;
}

.edit:hover {
  color: black;
  background-color: #e6e6e6;
}
