.buttonsContainer {
  height: 10rem;
  display: flex;
  position: absolute;
  right: 3rem;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.buttonsContainer div {
  border: 1px solid rgb(189, 189, 189);
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin: 0.3rem;
}

.buttonsContainer .hamBurger {
  display: none;
}

@media (max-width: 768px) {
  .buttonsContainer .hamBurger {
    display: flex;
    z-index: 3;
  }

  .buttonsContainer {
    height: 6rem;
    right: 2rem;
  }

  .buttonsContainer div {
    border: none;
    width: 1.8rem;
  }
}

@media print {
  .buttonsContainer {
    display: none;
  }
}
