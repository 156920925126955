.QRcont {
  width: 100vw;
  /* display: flex; */
}

.QRcont p {
  font-size: 2.25rem;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eefaff;
  outline: 1px solid #0088c2;
  color: #0088c2;
  border-radius: 1rem;
  margin: 0 1.5rem 0 1.5rem;
}

.QRcont img {
  height: 1.75rem;
  margin: 0.5rem;
  margin-right: 1rem;
}

.QRcont video {
  transform: scale(0.8);
  outline: 1px solid #0088c2;
  background-color: #f1fbff;
  border-radius: 1rem;
}
