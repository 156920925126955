.pButton {
  border-radius: 0.7rem;
  border: 1px solid #000000;
  background-color: #ffffff;
  color: #000000;
  font-size: 0.9rem;
  transition: 0.2s;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  width: 11rem;
  height: 2.7rem;
  line-height: 2.7rem; /* Set line-height equal to the height */
  vertical-align: middle; /* Align the text vertically in the middle */
}

.pButton input {
  display: none;
}
