.Sub {
  opacity: 0.5;
}

.unitTitleHolder {
}

@media print {
  .unitTitleHolder div {
    flex-direction: row !important;
  }

  .unitTitleHolder p {
    font-size: 0.7rem !important;
    font-weight: 300 !important;
    margin: 0 0.5rem 0 0rem !important;
    text-align: left;
  }
}
