.floorTitle {
  display: flex;
  flex-direction: row;
  background-color: #0088c2;
  font-size: 0.65rem;
  font-weight: 300;
  color: white;
  max-width: 10rem;
  padding: 0.1rem 0.7rem 0.1rem 0rem;
  border-radius: 1rem;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.floorTitle svg {
  height: 0.8rem;
}

.childUnits {
  display: flex;
  flex-direction: column;
}

@media print {
  .floorTitle {
    padding: 0;
    margin: 0;
    color: black !important;
  }

  .floorTitle {
    font-weight: 600;
  }

  .childUnits > *:nth-child(odd) label {
    background-color: rgb(230, 230, 230);
    print-color-adjust: exact;
  }
  .childUnits > *:nth-child(even) label {
    background-color: rgb(255, 255, 255);
    print-color-adjust: exact;
  }
}
