.pButton {
  height: 2.9rem;
  min-width: 10.9rem;
  border-radius: 0.7rem;
  border: 1px solid #c20000;
  background-color: #ffc5c5;
  color: #c20000;
  transition: 0.2s;
  font-weight: 500;
  margin-left: 1rem;
}

.pButton:hover {
  background-color: #c20000;
  color: white;
}

@media (max-width: 768px) {
  .pButton {
    min-width: 8.9rem;
  }
}
